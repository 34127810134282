const createFeedback = () => {
  const feedbackForm = document.querySelector('.js-feedback-form');

  if (feedbackForm) {
    const { location, body } = document
    const { origin } = location
    // const lang = document.querySelector('meta[lang]').attributes[1].value
    // const endpoint = `${origin}/${lang}/feedback`;
    const endpoint = `${origin}/feedback`;
    const allInputs = [...feedbackForm.querySelectorAll('.js-feedback-input')];
    const checkbox = feedbackForm.querySelector('.js-checkbox');
    const checkboxInput = checkbox.querySelector('input');

    const setInputErrorClass = input => input.classList.add('is-error');
    const removeInputErrorsClass = item => item.classList.remove('is-error');

    const setFormStatusLoading = () => feedbackForm.classList.add('loading');
    const removeFormStatusLoading = () => feedbackForm.classList.remove('loading');
    const setFormStatusLoaded = () => feedbackForm.classList.add('success');
    const setFormStatusFailed = () => feedbackForm.classList.add('failed');
    const setBodyOverflow = () => body.classList.add('is-overflow-hidden');
    const removeBodyOverflow = () => body.classList.remove('is-overflow-hidden');

    const resetAllInputs = () => {
      allInputs.forEach(input => {
        removeInputErrorsClass(input);
      });
    };

    const resetAllErrors = () => {
      // eslint-disable-next-line no-unused-vars
      const allErrors = [...document.querySelectorAll('.js-feedback-error')];

      allErrors.forEach(input => input.remove());
    };

    const resetForm = () => {
      resetAllErrors()

      setTimeout(() => {
        feedbackForm.reset();
        feedbackForm.className = 'feedback__form js-feedback-form';
      }, 5000)
    };

    const createErrorItem = (closestInput, content) => {
      closestInput.insertAdjacentHTML(
        'beforebegin',
        `
            <span class='feedback__error js-feedback-error'>
              ${content}
            </span>
        `
      );
    };

    checkbox.addEventListener('click', () => {
      if (checkbox.classList.contains('has-error')) {
        checkbox.classList.remove('has-error')
      }
    })

    feedbackForm.addEventListener('submit', (e) => {
      e.preventDefault();

      if (checkboxInput.checked) {
        resetAllInputs()
        resetAllErrors()
        setFormStatusLoading()
        setBodyOverflow()

        fetch(endpoint, {
          method: 'POST',
          body: new FormData(e.target),
        })
          .then(res => {
            if (res.ok) {
              setFormStatusLoaded()
              removeBodyOverflow()
              resetForm()
            }

            return res.json()
          })
          .then(data => {
            removeFormStatusLoading();
            removeBodyOverflow();

            if (data.violations) {
              data.violations.forEach(key => {
                switch (key.propertyPath) {
                  case 'name':
                    setInputErrorClass(allInputs[0])
                    createErrorItem(allInputs[0], key.title)
                    break
                  case 'email':
                    setInputErrorClass(allInputs[1])
                    createErrorItem(allInputs[1], key.title)
                    break
                  case 'phone':
                    setInputErrorClass(allInputs[2])
                    createErrorItem(allInputs[2], key.title)
                    break
                  default:
                }
              })
            }
          })
          .catch(() => {
            removeFormStatusLoading()
            setFormStatusFailed()
            removeBodyOverflow()
            resetForm()
          })
      } else {
        checkbox.classList.add('has-error')
      }
    })
  }
}

export default createFeedback;
