import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const initCatalogSlider = () => {
  return new Swiper('.catalog__slider', {
    loop: true,
    speed: 2000,
    // effect: 'fade',
    spaceBetween: 40,
    slidesPerView: 1,
    simulateTouch: false,
    // virtualTranslate: true,
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-catalog-button-next',
      prevEl: '.swiper-catalog-button-prev',
    },
  })
}

export default initCatalogSlider;
