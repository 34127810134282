import gsap from 'gsap';
import isTouchDevice from '../helpers/detectTouch'

const menu = () => {
  const animation = gsap.timeline({paused: true})
  const { body } = document;
  const hamburger = document.getElementById('hamburger');
  const header = document.querySelector('.js-header');
  const mobileNav = document.getElementById('mobile-navigation');
  const eventType = isTouchDevice() ? 'touchstart' : 'click';
  const navList = mobileNav.getElementsByClassName('nav__link');

  animation.to(mobileNav, {x: '0%', opacity: 1, duration: 0.3})

  function toggleMenu() {
    hamburger.classList.toggle('is-active');
    mobileNav.classList.toggle('is-opened');
    hamburger.classList.toggle('is-disabled');
    body.classList.toggle('is-overflow-hidden');
  }

  function toggleHeaderFilling() {
    const isHeaderFilled = header.classList.contains('header--scroll');

    if (!isHeaderFilled) {
      header.classList.add('header--scroll')
    }
  }

  const closeMenu = () => {
    toggleMenu()
    toggleHeaderFilling()
    animation.reverse();
  };

  const openMenu = () => {
    toggleMenu()
    toggleHeaderFilling()
    animation.play();
  };

  hamburger.addEventListener(eventType, () => {
    const isMobileNavOpened = mobileNav.classList.contains('is-opened');

    if (isMobileNavOpened) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  for (let i = 0; i < navList.length; i++) {
    navList[i].addEventListener('click', () => {
      closeMenu()
    });
  }
};

export default menu;
